@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background-color: rgb(248, 248, 248);
}

@layer components {
  .card {
    @apply bg-white md:rounded-lg drop-shadow-md;
  }

  .btn {
    @apply border-2 rounded-md px-4 py-2 uppercase text-sm border-transparent bg-gradient-to-b from-[#FF9300] to-[#FFBD62];
  }

  .btn.outline {
    @apply bg-none border-white outline-none;
  }

  .btn.outline.disabled {
    @apply bg-slate-500;
  }
}

@layer utilities {
  .text-shadow-sm {
    text-shadow: 2px 2px 4px black;
  }
  .text-shadow-md {
    text-shadow: 4px 4px 8px black;
  }
  .text-shadow-lg {
    text-shadow: 15px 15px 30px black;
  }
  .text-shadow-none {
    text-shadow: none;
  }
}



#__next {
  @apply h-full;
}